import React from 'react';
import './brand.css';
import {daikin,
  johnsoncontrols,
  herramentalmonterrey,
  grupocubimsa,
  delectric,
  rheem,
  brandenergysolutions,
  smd,
  varelenergysolutions,
  trane,
  mitsubishipower,
  mirage,
  metalinspec,
  ingersollrand,
  corpdelfuerte,
  namm,
  enersys,
  guntner,
  climaproyectos,
  alphametals
} from './imports';

const Brand = () => {
  return (
    <div className="netlog__brand section__padding" id="brand">
      <div>
        <img src={daikin} alt="daikin"></img>
      </div>
      <div>
        <img src={johnsoncontrols} alt="johnsoncontrols"></img>
      </div>
      <div>
        <img src={herramentalmonterrey} alt="herramentalmonterrey"></img>
      </div>
      <div>
        <img src={grupocubimsa} alt="grupocubimsa"></img>
      </div>
      <div>
        <img src={delectric} alt="delectric"></img>
      </div>
      <div>
        <img src={rheem} alt="rheem"></img>
      </div>
      <div>
        <img src={brandenergysolutions} alt="brandenergysolutions"></img>
      </div>
      <div>
        <img src={smd} alt="smd"></img>
      </div>
      <div>
        <img src={varelenergysolutions} alt="varelenergysolutions"></img>
      </div>
      <div>
        <img src={trane} alt="trane"></img>
      </div>
      <div>
        <img src={mitsubishipower} alt="mitsubishipower"></img>
      </div>
      <div>
        <img src={mirage} alt="mirage"></img>
      </div>
      <div>
        <img src={metalinspec} alt="metalinspec"></img>
      </div>
      <div>
        <img src={ingersollrand} alt="ingersollrand"></img>
      </div>
      <div>
        <img src={corpdelfuerte} alt="corpdelfuerte"></img>
      </div>
      <div>
        <img src={namm} alt="namm"></img>
      </div>
      <div>
        <img src={enersys} alt="enersys"></img>
      </div>
      <div>
        <img src={guntner} alt="guntner"></img>
      </div>
      <div>
        <img src={climaproyectos} alt="climaproyectos"></img>
      </div>
      <div>
        <img src={alphametals} alt="alphametals"></img>
      </div>
    </div>
  )
}

export default Brand