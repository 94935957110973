import React from 'react';
import Feature from '../../components/feature/Feature';
import './features.css';

const featuresData = [
  {
    title: 'Secos',
    text: 'Nissan estaquitas,\
    Camión de 3½Ton,\
    Rabón,\
    Torton,\
    Caja de 40 y 53 pies,\
    Full de 40 pies.',
  },
  {
    title: 'Plataformas',
    text: 'Nissan estaquitas,\
    Camión de 3½Ton,\
    Rabón,\
    Torton,\
    Caja de 40 y 53 pies,\
    Full de 40 pies.',
  },
  {
    title: 'Especiales',
    text: 'Cama baja,\
    Low boy,\
    Equipo sobre dimensionado,\
    Grúas,\
    Montacargas.',
  },
  {
    title: 'Hazmat',
    text: 'Cajas de 53 pies,\
    Camión de 3½Ton,\
    Torton.',
  }
];

const Features = () => (
  <div className="netlog__features section__padding" id="features">
    <div className="netlog__features-heading">
      <h1 className="gradient__text">Fletes terrestres locales, foráneos e internacionales.</h1>
      <p>Contamos con unidades propias y alianzas estratégicas con mas de cincuenta
    proveedores del transporte en el país así y en USA, de esta forma solucionamos las
    necesidades de distribución a cualquier parte de la república Mexicana y USA.</p>
    </div>
    <div className="netlog__features-container">
      {featuresData.map((item, index) => (
        <Feature title={item.title} text={item.text} key={item.title + index} />
      ))}
    </div>
    <div className="netlog__features-heading">
      <a href="https://netlogsolutions.azurewebsites.net/" target="_blank" rel="noopener noreferrer"><p>Contamos tambien con un sistema de monitoreo en linea y control de gasto.
      </p></a>
    </div>
  </div>
);

export default Features

