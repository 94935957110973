import React from 'react';
import './cta.css';

const CTA = () => (
  <div className="netlog__cta" id="contact">
    <div className="netlog__cta-content">
      {/* <p>Contactate con nosotros ya! Escribenos por aqui.</p> */}
      <h3>Contactanos ya y empieza a explorar nuestras soluciones.</h3>
    </div>
    <div className="netlog__cta-btn">
      
      <a href="https://wa.me/528120743170" target="_blank" rel="noopener noreferrer"><button type="button">Contáctanos</button></a>
    </div>
  </div>
);

export default CTA