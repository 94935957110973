import React from 'react';
import netlogLogo from '../../assets/logonetlog.png';
import './footer.css';

const Footer = () => (
  <div className="netlog__footer section__padding">
    <div className="netlog__footer-heading">
      <h1 className="gradient__text">NETLOG SOLUTIONS</h1>
    </div>

    <div className="netlog__footer-btn">
      <a href="https://wa.me/528120743170" target="_blank" rel="noopener noreferrer"><p>Contacta con nosotros</p></a>
    </div>

    <div className="netlog__footer-links">
      <div className="netlog__footer-links_logo">
        <img src={netlogLogo} alt="netlog_logo" />
        <p>Todos los derechos reservados. </p>
      </div>
      <div className="netlog__footer-links_div">
        <h4>Compañia</h4>
        <p>Terminos y Condiciones </p>
        <p>Politica de privacidad</p>
        <p>Contacto</p>
      </div>
      <div className="netlog__footer-links_div">
        <h4>Contáctanos</h4>
        <p>Apodaca, Nuevo León, México</p>
        <p>+52 812 074 3170</p>

        <p>contacto@netlog.solutions <br/>
        contacto@netlogsolutions.com.mx</p>
      </div>
    </div>

    <div className="netlog__footer-copyright">
      <p>@2022 NETLOG. Todos los derechos reservados.</p>
    </div>
  </div>
);

export default Footer;