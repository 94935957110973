import React from 'react';
import './header.css';
import people from '../../assets/people.png';
import ai from '../../assets/logistics.png';

const Header = () => {
  return (
    <div className="netlog__header section__padding" id="home">
      <div className="netlog__header-content">
        <h1 className="gradient__text ">
          Somos una empresa líder con mas
          de 15 años en el mercado.
        </h1>
        <p>
          Nuestras soluciones están enfocadas a ser funcionales, económicas, eficientes y
          son especializada alrededor de las necesidades de cada
          cliente. el diseño operativo es especifico de acuerdo a cada
          proyecto usando herramientas de calidad para así satisfacer
          las mas altas expectativas.
        </p>

        {/* <div className="netlog__header-content__input">
          <input type="email" placeholder="Tu email"></input>
          <button type="button">Recibir más información</button>
        </div>
         */}

        {/* <div className="netlog__header-content__people">
          <img src={people} alt="people"></img>
          <p>1,600 people requested access</p>
        </div> */}
      </div>
      <div className="netlog__header-image">
          <img src={ai} alt="ai"></img>
      </div>
    </div>
  )
}

export default Header