import React, { useState } from 'react';
import {RiMenu3Line, RiCloseLine} from 'react-icons/ri';
import logo from '../../assets/logonetlog.png';
import './navbar.css';

const Menu = () => (
  <>
  <p><a href="#inicio">Inicio</a></p>
  {/* <p><a href="#wnetlog">Que es NETLOG?</a></p> */}
  <p><a href="#features">Soluciones</a></p>
  <p><a href="#brand">Marcas</a></p>
  {/* <p><a href="#blog">Blog</a></p> */}
  <p><a href="https://wa.me/528120743170" target="_blank" rel="noopener noreferrer">Contacto</a></p>
  </>
)

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);

  return (
    <div className="netlog__navbar">
      <div className="netlog__navbar-links">
        <div className="netlog__navbar-links_logo">
          <img src={logo} alt="logo"></img>
        </div>
        <div className="netlog__navbar-links_container">
          <Menu />
        </div>
      </div>
      {/* <div className="netlog__navbar-sign"> 
        <p>Inicia sesión</p>
        <button type="button">Registrate</button>
      </div>*/}
      <div className="netlog__navbar-menu">
        {toggleMenu
          ? <RiCloseLine color="#293249" size={27} onClick={() => setToggleMenu(false)}/>
          :<RiMenu3Line color="#293249" size={27} onClick={() => setToggleMenu(true)}/>
        }
        {toggleMenu && (
          <div className="netlog__navbar-menu_container scale-up-center">
            <div className="netlog__navbar-menu_container-links">
              <Menu/>
              {/* <div className="netlog__navbar-menu_container-links-sign">
                <p>Inicia sesión</p>
                <button type="button">Registrate</button>
              </div> */}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Navbar