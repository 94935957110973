import React from 'react';
import possibilityImage from '../../assets/logistics2.png';
import './possibility.css';

const Possibility = () => (
  <div className="netlog__possibility section__padding" id="possibility">
    <div className="netlog__possibility-image">
      <img src={possibilityImage} alt="possibility" />
    </div>
    <div className="netlog__possibility-content">
      {/* <h4>Contactate con nosotros ya! Escribenos por aqui.</h4> */}
      <h1 className="gradient__text">Transporte maritimo</h1>
      <p>Contamos con presencia en los principales puertos del país, nuestros corresponsales están
    presentes en los principales puertos del mundo, dándonos versatilidad y comunicación para el
    seguimiento de los embarques de nuestros clientes.
    Transportamos mercancía de cualquier tipo de dimensiones de forma segura en las modalidades
    de Contenedor Completo (FCL), Contenedor Compartido (LCL), flat rack, etc.</p>
    </div>
  </div>
);

export default Possibility